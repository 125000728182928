/* .back{
    height: 100vh;
    background-image: url('../src/assets/images/ac2c0abfc3631949cab9dca01b5aafc4.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    overflow: hidden;
}

.layer{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(24, 24, 24,0.6);

} */


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
    max-width: 100vw;
    overflow: hidden auto !important;
    padding: 0 !important;
}

.productSwiper .swiper-slide {
    height: 380px;
    padding-bottom: 24px;
}
.productSwiper {
    width: 100%;
    height: 380px;
    padding-bottom: 32px;
}

.productSwiper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.productSwiper .swiper-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;
}

.productSwiper .swiper-pagination-bullet {
    border-radius: 0;
    margin: 0 !important;
    width: 60px;
    height: 2px;
    background: black;
}

#navbarSupportedContent .fa-close {
    display: none;font-size: 24px;padding: 12px;
}
.search_wrapper {
    display: none;
}
.drop_wrapper .swiper-button-prev::after, .swiper-button-next::after, .swiper-pagination {
    opacity: 0;
    transition: .2s ease-in all;
}

.drop_wrapper .swiper:hover .swiper-button-prev::after,.drop_wrapper .swiper:hover .swiper-button-next::after,.drop_wrapper .swiper:hover .swiper-pagination {
    opacity: 1;
}
.drop_wrapper .swiper-slide {
    padding-bottom: 28px;
}
@media (max-width: 768px) {
    .swiper-pagination {
        opacity: 1;
    }
    .search_wrapper {
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 0 !important;
        padding-left: 0px;
        border-top: 2px solid #c4c4c4;
        padding-left: 8px !important;
        margin-top: 8px;
    }    
    .search_wrapper input{
        font-size: 12px;
        border: none !important;
        width: 100%;
        padding: 8px 0;
    }
    .search_wrapper input:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }
    .search_wrapper ::placeholder {
        opacity: 1;
    }
    #navbarSupportedContent .fa-close {
        display: block;
    }
    .product_details_wrapper >div h1, .product_details_wrapper >div p {
        padding: 0 20px;
    }
    
    .product_details_wrapper .selectContainer {
        margin: 0 20px;
    }
    .product_details_wrapper .flex-column {
        margin: 0 20px;
    }
    .product_details_wrapper .detail_1 {
        margin-top: 0 !important;
    }
    .product_details_wrapper .navbar  .fa-heart  {
        position: absolute;
        z-index: 2;
    }
    #navbarSupportedContent {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 55;
        background: #fbfbfb;
        display: none;
        min-height: 200vh;
    }
    #navbarSupportedContent.open {
        display: block;
        animation-delay: 1s;
        animation: opacityAni .05s ease-in ;    
    }
    @keyframes opacityAni {
        0% {
          opacity: 0;
        }
        100% {
            opacity: 1;
        }
      }
    #navbarSupportedContent ul {
        margin-top: 0;
        border-top: 2px solid #3838384b;
    }
    #navbarSupportedContent li {
        padding: 0 !important;
    }
    #navbarSupportedContent ul a {
        color: #383838 !important;
        font-weight: 400 !important;
        padding: 10px 10px !important;
        border-bottom: 2px solid #3838384b;
        background-color: #fff;
        font-family: inter;
    }
    #navbarSupportedContent a {
        color: #a1a1a1 !important;
        font-weight: 500 !important;
        line-height: 20px !important;
        font-size: 13px !important;
    }
    #navbarSupportedContent .nav_icons {
        display: flex;
        flex-direction: column;
        align-items: start !important;
        gap: 8px;
    }
    .navbar  .fa-cart-shopping {
        font-family: 'Inter', sans-serif !important;
    }
    .navbar  .fa-cart-shopping::before {
        font-family: 'Inter', sans-serif !important;
        content: "Cart" !important;
        letter-spacing: normal !important;
    }
    .navbar  .fa-heart {
        font-family: 'Inter', sans-serif !important;
    }
    .navbar  .fa-heart::before {
        font-family: 'Inter', sans-serif !important;
        content: "Wishlist" !important;
        letter-spacing: normal !important;
    }
    .navbar  .fa-user {
        font-family: 'Inter', sans-serif !important;
    }
    .navbar  .fa-user::before {
        font-family: 'Inter', sans-serif !important;
        content: "Login" !important;
        letter-spacing: normal !important;
        color: #a1a1a1 !important;
        opacity: 1 !important;
        padding: 0 !important;
    }
    .contact-form-wrapper input {
        width: 100% !important;
    }
    .contact-form-wrapper textarea {
        border: none;
        margin: 0 !important;
        border: 1px solid;
        width: 100% !important;
        height: 160px;
        resize: none;
        border-bottom: 0;
    }
    .contact-form-wrapper {
        /* border-bottom: 2px solid black !important; */
    }
    .login_wrapper {
        border: none !important;
        width: 100%;
    }
    .login_wrapper h3 {
        font-size: 20px !important;
    }
    .form_wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }  
}
.drop_wrapper .swiper-button-prev::after, .drop_wrapper .swiper-button-next::after {
    font-size: 16px !important;
    font-weight: 600;
    color: black !important;
}
.drop_wrapper .swiper-button-next {
    right: -5px !important;
    left: auto;
}
.drop_wrapper .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .drop_wrapper .swiper-pagination-horizontal.swiper-pagination-bullets .drop_wrapper .swiper-pagination-bullet {
    width: 5px !important;
    height: 5px !important;
    background: black !important;
}
.drop_wrapper .swiper-button-prev {
    left: -5px !important;
    right: auto;
}
html {
    max-width: 100vw;
    overflow: hidden auto;
}
.login_wrapper label {
    color: #7C7C7C;
    font-weight: 500;
}
.login_wrapper input, .login_wrapper select {
    padding: 12px 16px !important;
  border-radius: 3px;
}

.login_wrapper input {
    padding: 10px 16px !important;
    border-radius: 0;
}
.login_wrapper input:focus {
    box-shadow: none;
    outline: none;
    border: none;
}

.login_wrapper button {
    padding: 10px 16px !important;
    border-radius: 0 !important;
}
.contact-form-wrapper *:focus {
    outline: none;
}

.toggleFeature button *{
    font-size: 14px !important;
    color: #383838;
}
.toggleFeature button{
    padding: 10px 16px !important;
    font-size: 16px !important;
    background-color: transparent;
    border-top: 1px solid;
    border-bottom: 1px solid;
}
.toggleFeature2 button{
    border-top: none !important;
}

.drop_prod_name {
    font-size: 12px !important;
    padding-top: 0 !important;
    margin-top: -4px;
}
.search_wrapper_main {
    border-bottom: 1px solid #000 ;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1028;
}
.search_wrapper_main form{
    display: flex;
    align-items: center;
    padding-left: 8px;
}

.search_wrapper_main input {
    padding: 16px 8px;
    width: 100%;
    border: none !important;
    font-size: 13px;
}

.search_wrapper_main input:focus {
    outline: none;
    border: none;
    box-shadow: none;
}
.contact-form-wrapper select{
    font-size: 18px;
    line-height: inherit;
    font-size: inherit;
}
.contact-form-wrapper input, .contact-form-wrapper select {
    border: none ;
    border-radius: 0;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    height: 70px;
    box-sizing: border-box;
    max-height: 70px;
    min-height: 70px;
    margin: 0;
}

@media (max-width: 767.98px) {
    .contact-form-wrapper input , .contact-form-wrapper select {
        border: none !important;
        border-right: none !important;
        border-bottom: 1px solid #000 !important;
        width: 100% !important;
    }
    .contact-form-wrapper textarea {
        padding: 20px;
    }
}

.coupon_form {
    border: 1px solid #000;
    display: flex;
    gap: 12px;
    
}
.coupon_form input {
    padding: 16px;
    width: 100%;
    border: none !important;
    font-size: 13px;

}
input:focus {
    outline: none;
    box-shadow: none;
}
.coupon_form button {
    background-color: #000;
    color: #fff;
    font-weight: 700;
    padding: 16px 8px;
    width: 100%;
    border: none !important;
    font-size: 13px;
    width: 250px;
    margin: 12px;
}
.home_img {
    /* width: 100%;
    max-width: 200px;
    max-height: 320px;
    display: block;
    margin: auto; */
    display: block;
    margin: auto;
    max-width: 200px;
    width: 100%;

}

@media (max-width: 767.98px) {
    .coupon_form {
        gap: 0;
        flex-direction: column;
    }
    .coupon_form input {
        border-bottom: 1px solid #000 !important;
    }
    .coupon_form button {
        width: 150px;
        margin: 16px auto;
    }
    .search_wrapper_main input{
        padding: 12px 8px;
        width: 100%;
        border: none !important;
        font-size: 12px;
    }
    .home_img {
        max-width: 210px;
        height: 180px;
        background-color: #E5002B;
    }

    .home_img.drop_img {
        max-width: 220px;
        height: auto
    }
}

.radio-drop {
    border: 1px solid #b9b9c1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b9b9c1;
}

.radio-drop input {
    display: none;
}

.radio-drop.selected {
    border: 1px solid #000;
    color: #000 ;
}


.fillter_wrapper .row {
    max-width: 100%
}

.cart_num {
    position: absolute;
    top: -8px;
    left: 0;
    background: red;
    width: 22px;
    height: 22px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.nav_opened .search_wrapper_main {
    opacity: 0 !important;
}

.home_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999999999999999;
    background: #F9F9F9;
    padding: 48px;
    width: 100%;
    max-width: 600px;
    text-align: left;
}

.home_popup h1 {
    font-size: 23px;
    font-weight: 700;
    line-height: 35.16px;
    text-align: left;
    color: #060606;
    margin-bottom: 29px;
}

.home_popup h3 {
    font-size: 18px;
    font-weight: 700 !important;
    line-height: 21.09px;
    text-align: left;
    color: #959595;
    margin-bottom: 28px;
}

.home_popup select {
    border-width: 2px;
}

.home_popup button {
    padding: 10px 16px !important;
    border-radius: 0 !important;
    background: #000;
    width: 100%;
    color: #fff;
    margin-top: 24px;
    border-radius: 4px;
    font-family: Inter;
    font-size: 21px;
    font-weight: 500;
    line-height: 30.26px;
    text-align: center;
}


.hide_content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #00000062;
    z-index: 999999;
}

.sale_span {
    padding: 4px 40px;
    font-size: 13px !important;
    display: block;
    width: max-content;
    font-weight: 800 !important;
    line-height: 20px;
    color: #fff;
    background-color: #E5002B;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

@media (max-width: 767.98px) {
    .home_popup {
        padding: 20px;
        width: calc(100vw - 24px);
    }
    .home_popup h1 {
        font-size: 15px;
        font-weight: 600;
        line-height: 24.16px;
        text-align: left;
        color: #060606;
        margin-bottom: 7px;
    }
    .home_popup h3 {
        font-size: 18px;
        font-weight: 600 !important;
        line-height: 21.09px;
        text-align: left;
        color: #959595;
        margin-bottom: 17px;
        font-size: 13px !important;
    }
    .home_popup select {
        border-width: 2px;
        padding: 12px 12px;
        font-size: 13px;
        border-radius: 0;
        border-color: #808080a1;
    }
    .home_popup button {
        padding: 10px 16px !important;
        border-radius: 0 !important;
        background: #000;
        width: 100%;
        color: #fff;
        margin-top: 20px;
        border-radius: 4px;
        font-family: Inter;
        font-size: 13px;
        font-weight: 500;
        line-height: 24.26px;
        text-align: center;
    }
}

@media (max-width: 767.98px) {
    .sale_span {
        padding: 4px 20px;
        font-size: 10px !important;
        display: block;
        width: max-content;
        font-weight: 800 !important;
        line-height: 13px;
        color: #fff;
        background-color: #E5002B;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
}

.redBorder {
    border-color: #E5002B;
}

.dorp-link svg {
    display: none;
}

@media (max-width:  767.98px) {

    .dorp-link svg {
        display: block;
    }
}

.swiper-scrollbar  {
    width: 50% !important;
    margin-left:25% !important;
    height: 2px !important;
    overflow: hidden;
}

.swiper-scrollbar-drag {
    background-color: black !important;
}

.bold {
    font-weight: bold;
}