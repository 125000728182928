@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');


.productDetailsContainer {
    display: flex;
    flex-direction: row; /* Stack vertically on smaller screens */
    gap: 20px; /* Space between the images and details */
    padding: 20px;
    padding-top: 0 !important;
    margin-top: 60px; /* Adjust this value based on your navbar height */
}

.imagesContainer {
    display: flex;
    flex: 1;
    flex-direction: column; /* Display images in a column */
    gap: 10px; /* Adjust as needed for space between images */
}
  select{
    border: 1px solid rgba(0, 0, 0, 0.571);
    border-radius: 4px;
    padding: 15px 10px;
    width: 100% ;
    font-size: 16px ;
    font-weight: 300 ;
    color: #00000099 ;
    font-family: 'Inter', sans-serif;
    background: transparent;
    border-radius: 3px;
  }
.productImage {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
     /* border-right: 1px solid black; 
    border-bottom: 1px solid black; */
    padding: 5px;
    object-fit: contain; 
    /* box-shadow: 0 2px 5px rgba(0,0,0,0.1); */
    margin-bottom: 0; /* Remove bottom margin */
}

@media (max-width: 767.98px) {
    .productImage {
        width: 86%;
        height: 364px;
        object-fit: contain; 
        background-position: center;
    }
    
}

.detailsContainer {
    padding: 10px;
    flex: 2;
}

.detailsContainer h1,
.detailsContainer p {
    margin-bottom: 0 !important;
    font-weight: 700;
    font-size: 17px;
    font-family: 'Inter' , sans-serif;
}
.detailsContainer1 p{
    font-weight: 400;
    font-size: 12px;
    font-family: 'Roboto' , sans-serif;
}


.buyBtn, .addBtn {
    width: 100%; /* Make buttons 100% width */
    padding: 15px;
    transition: all 1s;
    text-decoration: none;
    text-align: center;
    border: 1px solid #000;
}

.buyBtn {
    margin-bottom: 10px;
    background-color: black !important;
    color: white;
    font-weight: 600;
    font-family: 'Roboto' , sans-serif;
}

.buyBtn:hover {
    background-color: white !important;
    color: black !important;
    border: 1px solid black !important;
}
.detaills{
    font-size: 16px !important;
    font-weight: 300;
}
.specificDetail p ,.specificDetail strong{
    font-weight: 300 ;
    font-size: 12px;
    font-family: 'Inter' , sans-serif;

}
.addBtn {
    background-color: white !important;
    color: black;
    font-weight: 500;
    font-family: 'Inter' , sans-serif;
}

.addBtn:hover {
    background-color: black !important;
    color: white !important;
    border: 1px solid black !important;
}

.btnProductDetails {
    border-left: 0 !important;
    border-right: 0 !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .productDetailsContainer {
        height: auto;
        flex-direction: column; /* Arrange side by side on larger screens */
        padding: 0;
    }
    .imagesContainer {
        padding: 10px 0 0
    }
    .detailsContainer {
        padding: 0;
    }
    .imagesContainer {
        width: 100%;
        margin: auto;
    }
    .detailsContainer1 p{
        width: 200px;
        line-height: 14.06px;

    }
    .detailsContainer1{
        border-bottom: 1px solid black;
        padding-bottom: 24px;
        margin-bottom: 56px;
    }
    .detail{
        border-top: 1px solid black;
        margin-top: 0 !important;
    }
   
    .addBtn {
        margin-bottom: 16px;
    }
}