.flexContainer {
  display: flex;
  flex-direction: row; /* Default direction for larger screens */
}

.all {
  display: flex;
  width: 100%;
}

.flexColumn {
  display: flex;
  padding: 15px;
  width: 70%;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.flexColumn2 {
  width: 40%;
}
.imageContainer {
  width: 450px;

  background-size:cover;
  position: relative;
   background-position:center;
  background-repeat: no-repeat;
  border-bottom:none;
    }

.removeBtn {
  background-color: #E5002B !important;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 20px;
  border: 0;
  font-family: 'Roboto' , sans-serif;
}

.customlist {
  padding-left: 5px;
}

.customlist .listitem {
  font-family: 'Inter' , sans-serif;
  font-size: 15.0px;
  line-height: 15.3px;
  position: relative;
  padding-left: 10px;
  margin-bottom: 5px;
  font-weight: 300;
}

.line {
  padding: 4px 0;
  width: 100% !important;
  display: -webkit-box;
  -webkit-box-orient: vertical; 
  overflow: hidden;
  -webkit-line-clamp: 12;
}

.customlist .listitem::before {
  content: '•';
  position: absolute;
  left: 0;
  color: rgb(187, 187, 187);
}

.orderPara {
  color: #4D4D4D;
  font-weight: 400;
}

.orderPara1 {
  color: #4D4D4D;
  font-weight: 700;
}

.dashed {
  width: 100%;
  border-top: 1px dashed rgb(255, 62, 62);
  margin-top: 20px;
  padding-top: 20px;
}
.Continue {
width: 560px;
margin: auto;
background-color: rgb(0, 0, 0);
 color: azure;
 display: flex;
 padding: 8px;
text-transform: capitalize;
margin-left: auto;
 text-decoration: none;
 text-align:center;




}

.Continue:hover {
  background-color: red;

}

.light {
  font-weight: 500;
  font-family: 'Roboto' , sans-serif;
}
.color{
  font-family: 'Roboto' , sans-serif;

}
.size{
  font-family: 'Inter' , sans-serif;

}
.move{
  text-decoration: none;
  font-family: 'Roboto' , sans-serif;

}
.quantity{
  font-family: 'Roboto' , sans-serif;

}


@media (max-width: 768px) {
  .line {
    -webkit-line-clamp: 2;
  }
 body{
   overflow: hidden;

 } 
  .flexContainer {
    flex-direction: column;
    align-items: center; /* Center items vertically */
  }

  .all {
    width: 100%;
    flex-direction: row; /* Stack items vertically */
  }
  .imageContainer img {
    max-width: 100%; /* Ensure image doesn't overflow */
    max-height: 100%; /* Ensure image doesn't overflow */
    object-fit: contain; /* Maintain aspect ratio */
  }

.flexColumn p{
  padding: 0rem;
  margin: 0px;
}
  .flexColumn {
    height: 213px;
    width: 70%; /* Full width for content */
    
    
  }
  .customlist {
    font-size: 1.0px;
    font-weight: 300;
    padding: 0%;
  }
  .customlist {
font-size:10px;
  }
  .imageContainer {
    width: 199px;
    height: 213px;
    background-size:cover;
    position: relative;
     background-position:center;
    background-repeat: no-repeat;
    border-bottom:none;
      }


  /* .imageContainer img {
    width: 100%;
  } */

  .flexColumn2 {
    width: 100%; /* Full width for summary section */
  }

  .light{
  width: 144px;
 color: rgb(85, 85, 85);
   
  }
  .removeBtn{
    font-size: 10px;
    width: 70px;
    
    /* position: relative; */
    z-index: 2;
    font-weight: 800;

  }
  

  .color{
    font-weight: 500;
    font-size: 9.5px;
  }
  .size{
    font-weight: 600;
    font-size: 12px;
  }
  .move{
    font-weight: 500;
    font-size: 11px !important;
  }
  .quantity{
    font-weight: 500;
    font-size: 8.2px;
  }
  .orderPara1{
    font-size: 18px;
    font-weight: 700;
  }
  .Continue{
width: 400px;
text-align: center;
}

.Cart{
  font-family: 'Inter' , sans-serif;
  font-size: 10px;
  line-height: 7.3px;
  position: relative;
  padding-left: 10px;
  margin-bottom: 5px;
  font-weight: 300;
}

.customlist .listitem {
  font-family: 'Inter' , sans-serif;
  font-size: 8.0px;
  line-height: 15.3px;
  position: relative;
  padding-left: 10px;
  margin-bottom: 5px;
  font-weight: 300;
}

.hh 
{
   font-size: 20px !important;
position: relative;
 top:-12px;
   font-weight: 200;
}
}