
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.rowFooter {
    background-color: rgba(220, 220, 220, 1) !important;
    display: flex;
}

a {
    cursor: pointer;
}

h3,
a,
span {
    font-family: 'Inter', sans-serif;
}
.arrowIcon{
    font: 2px;
}
h3{
    font-weight: 300 !important;
    font-size: 17px !important;
}

a,
 .arrowIcon{
    font-size: 15px !important ; 
    font-weight: 300;
} 
.banagia,span{
    font-size: 12px !important ; 
    font-weight: 400 !important;
}
.foot {
    background-color: rgba(220, 220, 220, 1) !important;
    padding: 10px 0; /* Increase padding for height */
}

.fs4 {
    font-size: 1.5rem;
}

.textDecorationUnderline {
    text-decoration: underline;
}
.btnProductDetails {
    border-left: 0 !important;
    border-right: 0 !important;
}

.arrowIcon{
    padding-right: 10px;
}

.webbing{
    transition: all 0.5s;
    cursor: pointer !important;
    text-decoration: none;
    color: black;
}

.webbing:hover{
    color: blue !important;
}
@media (min-width: 900px) {
    .rowFooter {
        height: 300px;
    }

}






/* Center text on smaller screens */
@media (max-width: 768px) {
    
    .rowFooter {
        flex-direction: column;
        
        margin: auto;
    }
    
    .rowFooter * {
        font-size: 12px !important;
    }
}