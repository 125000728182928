@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.backAbout {
    height: 40vh; /* Adjust height as needed */
    background-image: url('../../assets/images/ac2c0abfc3631949cab9dca01b5aafc4.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
  }
  
  .layerAbout {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom , rgba(0, 0, 0, 0.899) ,rgba(255, 255, 255, 0));
  }
  
  .backAbout p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, 1);
    text-align: center;
    font-size: 3em; /* Example font size */
    font-weight: 300;
  }
  
  .container {
    max-width: 1200px; /* Adjust max-width as needed */
    margin: 10vh auto; /* Center align and adjust top margin */
    padding: 0 20px; /* Adjust horizontal padding */
  }
  
  .paragraph {
    line-height: 29.5; /* Example line height */
    font-size: 24px; /* Example font size */
    font-weight: 700;
    margin-bottom: 20px; /* Example bottom margin */

  }
  
  .copyRight {
    text-align: center;
    margin-top: 50px; /* Example top margin */
  }
  
  .btnAbout {
    color: white !important;
    background-color: black !important;
    width: 100%; /* Full width button */
    max-width: 280px; /* Max width for larger screens */
    height: 59px; /* Example height */
    margin-top: 50px;
    padding: 10px;
  }


  
  @media (max-width: 768px) {
    .backAbout{
      margin-top: 40px;
    }
    .container {
      margin-top: 5vh; /* Adjust top margin for smaller screens */
    }
  
    .backAbout p {
      font-size: 2em; /* Example font size for smaller screens */
    }
    
    .paragraph {
      line-height: 20px;
      font-size: 14px;
    }
    .btnAbout {
      width: 100vw;
      min-width: 100%;
      border-radius: 4px;
      height: 56px;
    }
  }
  