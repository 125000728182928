/* Navbar.module.css */

.logo {
  width: 10%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  cursor: pointer;
}

@media (max-width: 992px) {
  .logo {
    top: 10px; /* Adjust as needed */
    width: 20%;
  }
}

.navbarToggler {
  background-color: transparent !important;
  border: none !important;
  color: transparent !important;
}

.navbarTogglerIcon {
  background-image: none !important;
  color: white !important;
}

.navbarTogglerIcon::before {
  content: '\f0c9'; /* Unicode for FontAwesome bars */
  font-family: 'Font Awesome 5 Free'; /* FontAwesome font family */
  font-weight: 900; /* FontAwesome font weight */
  color: white;
  font-size: 1.25rem;
}
